import styled from "styled-components";

export const Background = styled.div`
  background-image: url(${process.env.PUBLIC_URL + "/images/footer-bg.png"});
  background-size: cover;
`;

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 300;
  color: #4d4e4e;
  padding-top: 50px;
  padding-bottom: 100px;

  .img-logo {
    width: 160px;
    margin-bottom: 35px;
  }

  .icon-footer {
    > *:first-child {
      margin-right: 10px;
    }
    > *:last-child {
      margin-left: 10px;
    }
  }

  .text-footer {
    width: 50%;
    text-align: center;
    margin-bottom: 20px;
    
    @media only screen and (max-width: 480px) {
      width: 90%;
    }
  }
`;

import { Background, Contents } from "../styles/FooterStyle";
import { RiMapPinFill } from "react-icons/ri";
const Footer = () => {
  return (
    <Background>
      <div className="container-sm">
        <Contents>
          <img
            src={process.env.PUBLIC_URL + "/images/Logo.svg"}
            alt={process.env.PUBLIC_URL + "/images/Logo.svg"}
            className="img-logo"
          />
          <div className="text-footer">
            เครื่องมือในการบริหารจัดการทีมขายและยอดขายให้มีประสิทธิภาพสูงสุด
            เกิดจากแนวคิดและความต้องการในการจัดระเบียบข้อมูลและพัฒนาระบบ
            การทำงานของทีมขาย (Sale team) บนเว็บไซต์สำเร็จรูปที่มีคุณภาพ
          </div>
          <div className="icon-footer">
            <img
              src={process.env.PUBLIC_URL + "/images/face-footer.svg"}
              alt={process.env.PUBLIC_URL + "/images/face-footer.svg"}
              onClick={() =>
                window.open("https://www.facebook.com/WorkmotionCorp")
              }
              style={{ cursor: "pointer" }}
            />

            <RiMapPinFill
              style={{ cursor: "pointer" }}
              size={38}
              onClick={() =>
                window.open("https://g.page/WorkmotionCreative?share")
              }
              color="#00184b"
            />
          </div>
        </Contents>
      </div>
    </Background>
  );
};

export default Footer;

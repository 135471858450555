import styled from "styled-components";

export const Background = styled.div`
  background-image: url(${process.env.PUBLIC_URL + "/images/tap-bg.png"});
  background-size: cover;
`;

export const Contents = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;

  > * {
    width: 50%;
  }
  > *:first-child {
    display: flex;
    flex-direction: column;
  }
  > *:last-child {
    display: flex;
    justify-content: flex-end;
  }

  .label-top {
    color: #dc2366;
    font-size: 20px;
    font-weight: 700;
  }

  .label-bottom {
    color: #ffffff;
    font-weight: 500;
    font-size: 30px;
  }
`;

export const ButtonTap = styled.button`
  padding: 13px 24px 13px 24px;
  background: linear-gradient(270deg, #f15c6a 0%, rgba(241, 92, 106, 0) 100%),#dc2366;
  box-shadow: 0px 0px 40px rgba(255, 143, 155, 0.5);
  border-radius: 3px;
  border: 0px;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
`;

import styled from "styled-components";

export const Background = styled.div`
  background-image: url(${process.env.PUBLIC_URL + "/images/bg-articles.svg"});
  background-size: cover;
`;

export const Contents = styled.div`
  padding-top: 10%;
  padding-bottom: 6%;
  display: flex;
  flex-direction: column;

  > *:first-child {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    > * {
      margin-bottom: 8px;
    }
  }

  .label-top {
    color: #dc2366;
    font-weight: 700;
    font-size: 20px;
  }

  .label-center {
    color: #00184b;
    font-weight: 500;
    font-size: 30px;
  }

  .label-bottom {
    color: #4d4e4e;
    font-size: 16px;
    font-weight: 300;
  }
`;

export const CardBody = styled.div`
  display: flex;
  justify-content: space-between;

  > * {
    width: 30%;
    height: 520px;

    @media only screen and (max-width: 490px) {
      width: 100%;
    }
  }

  .crad-body-img {
    position: relative;
    img {
      width: 100%;
      height: 300px;
      object-fit: fill;

      @media only screen and (max-width: 991px) {
        height: 200px;
      }

      @media only screen and (max-width: 490px) {
        height: 300px;
      }
    }
  }

  .crad-body-date {
    color: #ffffff;
    background: #f15c6a;
    font-weight: 700;
    font-size: 16px;
    position: absolute;
    bottom: 0px;
    padding: 7px 16px 7px 16px;
  }

  .crad-body-text {
    height: 179px;
    background: #ffffff;
    padding: 4%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media only screen and (max-width: 991px) {
      height: 149px;
    }

    label {
      font-weight: 500;
      font-size: 20px;
      color: #00184b;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; 
              line-clamp: 2; 
      -webkit-box-orient: vertical;

      @media only screen and (max-width: 991px) {
        font-size: 16px;
      }
      @media only screen and (max-width: 767px) {
        font-size: 14px;
        -webkit-line-clamp: 3; 
      }
    }

    span {
      color: #4d4e4e;
      font-weight: 300;
      font-family: 'Mitr';
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
              line-clamp: 2; 
      -webkit-box-orient: vertical;
      @media only screen and (max-width: 991px) {
        font-size: 13px;
      }
      @media only screen and (max-width: 767px) {
        font-size: 11px;
      }
    }

    a {
      color: #00184b;
      text-decoration: none;
    }
  }
`;

export const SwiperDesktop = styled.div`
  @media only screen and (max-width: 490px) {
    display: none;
  }
  .swiper-pagination-bullet {
    width: 30px;
    height: 5px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #000;
    opacity: 1;
    background: #00184b;
    border-radius: 0;
  }

  .swiper-pagination-bullet-active {
    color: #fff;
    background: #f15c6a;
  }
`;

export const SwiperMoblie = styled.div`
  display: none;
  @media only screen and (max-width: 490px) {
    display: block;
  }

  .swiper-pagination-bullet {
    width: 30px;
    height: 5px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #000;
    opacity: 1;
    background: #00184b;
    border-radius: 0;
  }

  .swiper-pagination-bullet-active {
    color: #fff;
    background: #f15c6a;
  }
`;

import styled from "styled-components";

export const Background = styled.div`
  background-image: url(${process.env.PUBLIC_URL + "/images/baner-bg.png"});
  background-size: cover;
`;

export const Contents = styled.div`
  display: flex;
  justify-content: space-around;
  padding-bottom: 4%;
  padding-top: 18%;

  @media only screen and (max-width: 580px) {
    padding-top: 90px;
  }

  > * {
    width: 100%;
    display: flex;
    flex-direction: column;

    > button {
      width: 155px;
      height: 40px;
      border-radius: 3px;
      border: 0px;
      background: linear-gradient(
          90deg,
          #fbe6e8 0%,
          rgba(251, 230, 232, 0) 100%
        ),
        #ffc6cb;
      color: #dc2366;
      font-weight: 700;
    }

    .label-en {
      color: #00184b;
      font-weight: 700;
      font-size: 59px;
    }

    .label-top {
      color: #00184b;
      > *:first-child {
        font-weight: 600;
        font-size: 42px;
      }
      > *:last-child {
        font-size: 24px;
      }
    }

    .label-bottom {
      color: #00184b;
      > *:first-child {
        font-size: 24px;
      }
      > *:last-child {
        font-weight: 600;
        font-size: 42px;
      }
    }

    span {
      font-weight: 300;
    }

    .img-lt {
      width: 100%;
    }
  }

  > *:first-child {
    margin-right: 10px;
  }
  > *:last-child {
    margin-left: 10px;
    justify-content: center;
    @media only screen and (max-width: 580px) {
      display: none;
    }
  }
`;

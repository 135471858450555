import { Contents, Background } from "../styles/ContentPage";
import Navbar from "../componentes/Navbar";
import Footer from "../componentes/Footer";
import { useNavigate } from "react-router-dom";
import { BsCheckLg, BsFillBrightnessAltHighFill } from "react-icons/bs";
import { FaHandPointDown } from "react-icons/fa";
import { GiRotaryPhone } from "react-icons/gi";
import { BiWorld } from "react-icons/bi";

const BatterSale = () => {
  const navigate = useNavigate();
  const onChangeHandle = () => {
    navigate("/");
  };
  window.scrollTo(0, 0)
  return (
    <Background>
      <Navbar onChangeHandle={onChangeHandle} />
      <div className="container-sm">
        <Contents>
          <label className="label-header">
            Better Sale Better Service
            เครื่องมือช่วยเพิ่มประสิทธิภาพในการบริหารจัดการทีมขายและยอดขาย
          </label>
          <div className="content">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/images/Saleserv-May-P1-01.jpg"}
                alt={"เชื่อมโยงทุกหน้าที่ สร้างงานขายที่ดีกว่า"}
              />
            </div>
            <div>
              <label style={{ marginBottom: 15 }}>
                'SaleServ' เพิ่มประสิทธิภาพการบริหารจัดการทีมขาย
                เสริมสร้างยอดขายอย่างก้าวกระโดด
              </label>
              <label>
                บอกลาการจัดการ #ทีมขาย แบบเดิมๆ พร้อมก้าวสู่การบริหารแบบยุคใหม่
                ภายใต้ระบบ PC Sale Solutions แพลตฟอร์มบริหารทีมขายที่จะช่วย
                ‘ผู้บริหาร’...
              </label>
              <label><BsCheckLg color="green" />  ติดตามงานทีมเซลล์ได้อย่างเป็นระบบ </label>
              <label><BsCheckLg color="green" />  วัดผลการทำงานได้อย่างแม่นยำ </label>
              <label><BsCheckLg color="green" />  เข้าใจ Customer Insight อย่างลึกซึ้ง </label>
              <label style={{ marginBottom: 15 }}><BsCheckLg color="green" />  รายงานสถิติการขายอย่างชัดเจน </label>
              <label style={{ marginBottom: 15 }}>
                เพื่อการวางกลยุทธ์ที่ทันสมัย ตอบโจทย์ทุกภาคธุรกิจ
                และการจัดการแบบคล่องตัวเพราะระบบ #Saleserv รองรับการใช้งานทั้งบน
                PC และ Smart Phone จะอยู่ที่ไหน เมื่อไหร่ ก็ตรวจสอบได้
              </label>
              <label style={{ marginBottom: 15 }}>
                สร้างทีมขายคุณภาพ พุ่งสู่เป้าหมายในการปิดดีลอย่างรวดเร็ว ให้
                'Saleserv' ช่วยคุณ!
              </label>
              <label style={{ marginBottom: 15 }}>Better Sales, Better Service </label>
              <label>ติดต่อสอบถามเพิ่มเติมได้ที่ <FaHandPointDown color="#d1ad8e" /></label>
              <label><BsFillBrightnessAltHighFill /> Inbox : m.me/salesserv.co</label>
              <label><GiRotaryPhone color="#b50d1b" /> Tel : 064-6359246</label>
              <label style={{ marginBottom: 15 }}><BiWorld color="#0fad4c" /> Website: <a href=" https://salesserv.co">https://salesserv.co</a> </label>
              <label >#Saleserv #เว็บไซต์ #การจัดการทีมขาย #BetterSalesBetterService #POS #Salemanagement #บริหารการขาย #บริหารทีมเซลล์</label>
            </div>
          </div>
        </Contents>
      </div>
      <Footer />
    </Background>
  );
};

export default BatterSale;

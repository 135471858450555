import styled from "styled-components";

export const Contents = styled.div`
  display: flex;
  padding-bottom: 14%;
  padding-top: 18%;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 991px) {
    padding-top: 25%;
  }

  .label-header {
    color: #00184b;
    font-weight: 500;
    font-size: 30px;
    text-align: center;
    width: 80%;
    @media only screen and (max-width: 991px) {
      font-size: 20px;
    }
  }

  .content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 40px;
    font-family: 'Mitr';
  
    > *:first-child {
      width:38%;
      img {
        width:100%;
      }
      @media only screen and (max-width: 991px) {
        width:80%;
        margin-bottom: 35px;
      }
    }

    >*:last-child {
      width:58%;
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: 991px) {
        width:80%;
        font-size: 12px;
      }
    }

    @media only screen and (max-width: 991px) {
      flex-direction: column;
      align-items: center;
    }

  }


  
`;

export const Background = styled.div`
  background-image: url(${process.env.PUBLIC_URL + "/images/bg-articles.svg"});
  background-size: cover;
`;

import { Contents, Background } from "../styles/ContentPage";
import Navbar from "../componentes/Navbar";
import Footer from "../componentes/Footer";
import { useNavigate } from "react-router-dom";
import { BsCheckLg, BsFillBrightnessAltHighFill } from "react-icons/bs";
import { FaHandPointDown } from "react-icons/fa";
import { GiRotaryPhone } from "react-icons/gi";
import { BiWorld } from "react-icons/bi";

const SaleHalp = () => {
  const navigate = useNavigate();
  const onChangeHandle = () => {
    navigate("/");
  };
  window.scrollTo(0, 0)
  return (
    <Background>
      <Navbar onChangeHandle={onChangeHandle} />
      <div className="container-sm">
        <Contents>
          <label className="label-header">
            ขายถึงไหน ปิดเท่าไหร่ จัดการยังไง Saleserv ช่วยได้ 
          </label>
          <div className="content">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/images/Saleserv-June-02-01.jpg"}
                alt={"ขายถึงไหน ปิดเท่าไหร่ จัดการยังไง Saleserv ช่วยได้"}
              />
            </div>
            <div>
              <label style={{ marginBottom: 15 }}>
                รู้ทันการทำงานของทีม Sales บริหารจัดการงานขายอย่างคล่องตัวด้วย 'SalesServ'
              </label>
              <label style={{ marginBottom: 15 }}>
                เปลี่ยนการทำงานแบบเดิมๆ ที่ยุ่งยาก ก้าวสู่การบริหารจัดการงานขายแบบรูปแบบใหม่ด้วย SalesServ เครื่องมือสำคัญที่จะช่วยให้ทุกๆ วันในการบริหารทีมขายของคุณดำเนินไปอย่างสะดวก รวดเร็ว เข้าถึงทุกข้อมูล ด้วยระบบที่่ส่งเสริม Sales Process Management และ Sales Team Management ที่จะช่วยคุณ…
              </label>
              <label><BsCheckLg color="green"/>  ผลักดันการสร้างยอดยอดขายให้ก้าวกระโดด </label>
              <label><BsCheckLg color="green"/>  ส่งเสริมความสามารถของทีมขายเพื่อก้าวไปสู้เป้าหมายในการสร้างยอดขาย </label>
              <label><BsCheckLg color="green"/>  สร้างผลกำไรให้กับองค์กรและสร้างผลตอบแทนที่น่าพึงพอใจแก่นักขาย </label>
              <label style={{marginBottom: 15}}><BsCheckLg color="green"/>  ประมาณการรายได้ของกิจการได้เป็นอย่างดี </label>
              <label style={{ marginBottom: 15 }}>
                พาธุรกิจของคุณเข้าสู่โลกแห่งการทำงานในยุคดิจิทัล ส่งเสริมศักยภาพของทีมขาย เพื่อเดินทางสู่ความสำเร็จในการปิดยอดอย่างที่ต้องการด้วยระบบการจัดการที่มีคุณภาพ 
              </label>
              <label>
                SalesServ: Better Sales, Better Service
              </label>
              <label style={{ marginBottom: 15 }}>
                เครื่องมือช่วยเพิ่มประสิทธิภาพในการบริหารจัดการทีมขายและยอดขาย
              </label>

              <label>
                ติดต่อสอบถามเพิ่มเติมได้ที่ <FaHandPointDown color="#d1ad8e" />
              </label>
              <label>
                <BsFillBrightnessAltHighFill /> Inbox : m.me/salesserv.co
              </label>
              <label>
                <GiRotaryPhone color="#b50d1b" /> Tel : 064-6359246
              </label>
              <label style={{ marginBottom: 15 }}>
                <BiWorld color="#0fad4c" /> Website:{" "}
                <a href=" https://salesserv.co">https://salesserv.co</a>
              </label>
              <label>
                #SalesServ #เว็บไซต์ #การจัดการทีมขาย #BetterSalesBetterService
              </label>
            </div>
          </div>
        </Contents>
      </div>
      <Footer />
    </Background>
  );
};

export default SaleHalp;

import {
  NavbarMobile,
  ContentsMoblie,
  MenuMoblie,
  ButtomMoblie,
  NavbarDesktop,
  MemuTap,
  MemuButtom,
} from "../styles/Navber";
import React, { useState } from "react";
import { FiMapPin } from "react-icons/fi";

const Navbar = ({ onChangeHandle }) => {
  const [open, setOpen] = useState("80px");
  const [body, setBody] = useState("none");

  const clickHamburger = () => {
    setOpen("0px");
    setBody("100%");
  };

  const clickClose = () => {
    setOpen("80px");
    setBody("0%");
  };

  return (
    <>
      <div className="container-sm">
        <NavbarDesktop>
          <div className="menu-top">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/images/mail-icon.svg"}
                alt={process.env.PUBLIC_URL + "/images/mail-icon.svg"}
              />
              <label>@WorkmotionCorp</label>
              <img
                src={process.env.PUBLIC_URL + "/images/phone-icon.svg"}
                alt={process.env.PUBLIC_URL + "/images/phone-icon.svg"}
              />
              <label>064-6359246</label>
            </div>
            <div>
              <img
                src={process.env.PUBLIC_URL + "/images/face-icon.svg"}
                alt={process.env.PUBLIC_URL + "/images/face-icon.svg"}
                onClick={() =>
                  window.open("https://www.facebook.com/WorkmotionCorp")
                }
                style={{ cursor: "pointer" }}
              />
              <FiMapPin
                style={{ cursor: "pointer" }}
                size={20}
                onClick={() =>
                  window.open("https://g.page/WorkmotionCreative?share")
                }
              />
            </div>
          </div>
          <div className="menu-bottom">
            <img
              src={process.env.PUBLIC_URL + "/images/Logo.svg"}
              alt={process.env.PUBLIC_URL + "/images/Logo.svg"}
              className="img-logo"
            />
            <MemuTap>
              <div onClick={() => onChangeHandle("Home")}>Home</div>
              <div onClick={() => onChangeHandle("Products")}>Products</div>
              <div onClick={() => onChangeHandle("About")}>About Us</div>
              <div onClick={() => onChangeHandle("Articles")}>Articles</div>
              <div onClick={() => onChangeHandle("Contact")}>Contact Us</div>
            </MemuTap>

            <MemuButtom>
              {/* <button
                onClick={() =>
                  window.open("https://service.salesserv.co/admin-login")
                }
              >
                Admin
              </button> */}
              <button
                onClick={() =>
                  window.open("https://service.salesserv.co/login")
                }
              >
                เข้าใช้งาน
              </button>
            </MemuButtom>
          </div>
        </NavbarDesktop>
      </div>

      <NavbarMobile style={{ height: `${open}` }}>
        <div>
          <img
            src={process.env.PUBLIC_URL + "/images/Logo.svg"}
            alt={process.env.PUBLIC_URL + "/images/Logo.svg"}
          />
          <img
            src={process.env.PUBLIC_URL + "/images/hamburger-icon.svg"}
            alt={process.env.PUBLIC_URL + "/images/hamburger-icon.svg"}
            onClick={() => clickHamburger()}
          />
        </div>
      </NavbarMobile>
      <ContentsMoblie style={{ width: `${body}` }}>
        <div>
          <div>
            <img
              src={process.env.PUBLIC_URL + "/images/Logo.svg"}
              alt={process.env.PUBLIC_URL + "/images/Logo.svg"}
              className="img-logo"
            />
            <img
              src={process.env.PUBLIC_URL + "/images/close-icon.svg"}
              alt={process.env.PUBLIC_URL + "/images/close-icon.svg"}
              onClick={() => clickClose()}
            />
          </div>
          <MenuMoblie>
            <div
              onClick={() => {
                onChangeHandle("Home");
                clickClose();
              }}
            >
              Home
            </div>
            <div
              onClick={() => {
                onChangeHandle("Products");
                clickClose();
              }}
            >
              Products
            </div>
            <div
              onClick={() => {
                onChangeHandle("About");
                clickClose();
              }}
            >
              About Us
            </div>
            <div
              onClick={() => {
                onChangeHandle("Articles");
                clickClose();
              }}
            >
              Articles
            </div>
            <div
              onClick={() => {
                onChangeHandle("Contact");
                clickClose();
              }}
            >
              Contact Us
            </div>
          </MenuMoblie>
          <ButtomMoblie>
            {/* <button
              onClick={() =>
                window.open("https://service.salesserv.co/admin-login")
              }
            >
              Admin
            </button> */}
            <button
              onClick={() => window.open("https://service.salesserv.co/login")}
            >
              เข้าใช้งาน
            </button>
          </ButtomMoblie>
        </div>
        <div></div>
      </ContentsMoblie>
    </>
  );
};

export default Navbar;

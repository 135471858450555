import styled from "styled-components";

export const Background = styled.div`
  background-image: url(${process.env.PUBLIC_URL + "/images/bg-about.png"});
  background-size: cover;
`;

export const Contents = styled.div`
  padding-top: 10%;
  padding-bottom: 6%;
  display: flex;

  @media only screen and (max-width: 490px) {
    flex-direction: column; 
  } 

  > * {
    width: 50%;
    @media only screen and (max-width: 490px) {
      width: 100%; 
    } 
  }

  > *:first-child {
    img {
      width: 90%;
      height: 90%;
    }
  }

  > *:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    > * {
      margin-bottom: 15px;
    }
  }

  .label-top {
    color: #dc2366;
    font-weight: 700;
    font-size: 20px;
  }

  .label-center {
    color: #00184b;
    font-weight: 700;
    font-size: 30px;
  }

  .label-bottom {
    color: #4d4e4e;
    > *:first-child {
      font-weight: 500;
      font-size: 16px;
    }
    > *:last-child {
      font-weight: 300;
    }
  }
`;

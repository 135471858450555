import {
  Background,
  ContentsCrad,
  CardBody,
  ButtonContact,
  Contenttext,
} from "../styles/ContactUs";

const TapButton = () => {
  return (
    <Background>
      <div className="container-sm">
        <Contenttext>
          <label className="lable-top">Contact Us</label>
          <label className="lable-center">
            Salesserv
            เครื่องมือที่จะช่วยบริหารทีมขายและยอดขายขององค์กรคุณให้มีประสิทธิภาพสูงสุด
          </label>
          <label className="label-bottom">
            ส่งข้อความติดต่อเราได้ที่นี่ หรือช่องทางการติดต่อด้านล่างนี้
          </label>
        </Contenttext>
        <ContentsCrad>
          <CardBody>
            <div>
              <input
                type="text"
                className="form-control"
                placeholder="ชื่อ-นามสกุล"
              />
              <input type="text" className="form-control" placeholder="อีเมล" />
              <input
                type="text"
                className="form-control"
                placeholder="เบอร์โทร"
              />
              <input
                type="text"
                className="form-control"
                placeholder="เบอร์โทร"
              />
              <textarea
                type="text"
                className="form-control textarea-cuntom"
                placeholder="ข้อความ"
              />
            </div>
            <div>
              <ButtonContact>ส่งข้อความ</ButtonContact>
            </div>
          </CardBody>

          <CardBody>
            <div className="contact-text ">
              <img
                src={process.env.PUBLIC_URL + "/images/contact-img.png"}
                alt={process.env.PUBLIC_URL + "/images/contact-img.png"}
                className="contact-img"
              />
              <div>
                <div className="contact-text">
                  <img
                    src={process.env.PUBLIC_URL + "/images/icon-contact.svg"}
                    alt={process.env.PUBLIC_URL + "/images/icon-contact.svg"}
                    className="icon-img"
                  />
                  <div >
                    <div>064-6359246</div>
                    <div>@WorkmotionCorp</div>
                    <div style={{ fontWeight: 400 , fontSize: 12 , marginTop:5}}>
                      บริษัท เวิร์คโมชั่น ครีเอทีฟ จำกัด(Workmotion Corp.)
                    </div>
                    <div style={{ fontWeight: 400 , fontSize: 12 , marginTop:8}}>
                      ตึกพร้อมพันธุ์ 1 ชั้น 4ซอย ลาดพร้าว 15 เขตจตุจักร
                      กรุงเทพมหานคร 10900
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </ContentsCrad>
      </div>
    </Background>
  );
};

export default TapButton;

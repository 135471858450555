import styled from "styled-components";

export const Contents = styled.div`
  padding-bottom: 6%;
  padding-top: 10%;
  display: flex;
  flex-direction: column;

  > *:first-child {
    display: flex;
    flex-direction: column;
    width: 50%;

    @media only screen and (max-width: 991px) {
      width: 100%;
    }

    .lable-top {
      color: #dc2366;
      font-size: 20px;
      font-weight: 700;
    }

    .lable-text {
      font-weight: 500;
      font-size: 30px;
      line-height: 40px;
      color: #00184b;
    }

    .lable-bottom {
      font-weight: 400;
      font-size: 16px;
    }

    > * {
      margin-bottom: 8px;
    }

    span {
      font-weight: 300;
    }
  }

  > *:last-child {
    margin-top: 40px;
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 40px;
    justify-items: center;

    @media only screen and (max-width: 991px) {
      grid-template-columns: auto;
    }

  }
`;

export const CardStyle = styled.div`
  width: 80%;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1);
  position: relative;

  @media only screen and (max-width: 490px) {
    width: 85%;
  }
`;

export const CardImage = styled.div`
  position: absolute;
  top: 25%;
  left: -8%;

  > * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  .img-ro {
    background: linear-gradient(
        221.96deg,
        #ffb075 10.73%,
        rgba(255, 176, 117, 0) 100%
      ),
      #ff6938;
    box-shadow: 0px 0px 25px rgba(242, 91, 38, 0.25);
  }

  .img-red {
    background: linear-gradient(270deg, #f15c6a 0%, rgba(241, 92, 106, 0) 100%),
      #dc2366;
    box-shadow: 0px 0px 25px rgba(250, 42, 68, 0.25);
  }

  .img-pring {
    background: #f15c6a;
    box-shadow: 0px 0px 25px rgba(255, 97, 97, 0.25);
  }

  .img-blue {
    background: linear-gradient(270deg, #5fc0ff 0%, rgba(95, 192, 255, 0) 100%),
      #37a4ff;
    box-shadow: 0px 0px 25px rgba(42, 157, 252, 0.25);
  }
`;

export const CardText = styled.div`
  margin: 24px 6% 8% 15%;
  display: flex;
  flex-direction: column;

  span {
    font-weight: 300;
    font-family: 'Mitr';
  }

  @media only screen and (max-width: 490px) {
    margin: 16px 2% 4% 22%;
  }  

  > *:first-child {
    color: #00184B;
    font-weight: 500;
    font-size: 30px;

    @media only screen and (max-width: 490px) {
      font-size: 26px; 
    }    
  }
`;

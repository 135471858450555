import Banner from "../componentes/Banner";
import Products from "../componentes/Products";
import TapButton from "../componentes/TapButton";
import AbuotUs from "../componentes/AboutUs";
import Articles from "../componentes/Articles";
import ContactUs from "../componentes/ContactUs";
import Navbar from "../componentes/Navbar";
import Footer from "../componentes/Footer";
import { useRef } from "react";

const SalePage = () => {

  const refBanner = useRef();
  const refProducts = useRef();
  const refAbuotUs = useRef();
  const refArticles = useRef();
  const refContactUs = useRef();

  const onChangeHandle = (myRef) => {
    switch (myRef) {
      case "Products":
        return refProducts.current.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      case "About":
        return refAbuotUs.current.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      case "Articles":
        return refArticles.current.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      case "Contact":
        return refContactUs.current.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });

      default:
        return refBanner.current.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
    }
  };

  return (
    <>
      <Navbar onChangeHandle={onChangeHandle} />

      <div ref={refBanner}>
        <Banner />
      </div>

      <div ref={refProducts}>
        <Products />
      </div>

      <div ref={refAbuotUs}>
        <TapButton />
      </div>

      <div>
        <AbuotUs />
      </div>

      <div ref={refArticles}>
        <Articles />
      </div>

      <div ref={refContactUs}>
        <ContactUs />
      </div>

      <Footer />
    </>
  );
};

export default SalePage;

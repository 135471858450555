import { Contents, Background } from "../styles/ContentPage";
import Navbar from "../componentes/Navbar";
import Footer from "../componentes/Footer";
import { useNavigate } from "react-router-dom";
import { BsFillBrightnessAltHighFill } from "react-icons/bs";
import { FaHandPointDown } from "react-icons/fa";
import { GiRotaryPhone } from "react-icons/gi";
import { BiWorld } from "react-icons/bi";
import { AiTwotonePushpin } from "react-icons/ai";

const SaleServManage = () => {
  const navigate = useNavigate();
  const onChangeHandle = () => {
    navigate("/");
  };
  window.scrollTo(0, 0)
  return (
    <Background>
      <Navbar onChangeHandle={onChangeHandle} />
      <div className="container-sm">
        <Contents>
          <label className="label-header">
            จัดการง่าย ใช้งานได้ทุกที่ อัพเดทได้ทุกเวลา
          </label>
          <div className="content">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/images/Saleserv-May-P2-01.jpg"}
                alt={"อัพเดทเร็ว จัดการง่าย ที่ไหนก็ทำได้"}
              />
            </div>
            <div>
              <label style={{ marginBottom: 15 }}>
                อัพเดทเร็ว จัดการง่าย ที่ไหนก็ทำได้!
              </label>
              <label style={{ marginBottom: 15 }}>
                เข้ายุคใหม่ ใครๆ ก็ต้องเปลี่ยนมาใช้ SalesServ
                เครื่องมือช่วยเพิ่มประสิทธิภาพในการบริหารจัดการทีมขายและยอดขายได้อย่างสะดวกและมีประสิทธิภาพมากยิ่งขึ้น
              </label>
              <label style={{ marginBottom: 15 }}>ทำไมต้อง SalesServ?</label>
              <label>
                เพราะ SalesServ
                มาพร้อมกับเครื่องมือที่ตอบโจทย์ด้านการขายอย่างตรงจุด
                ที่คุณสามารถ…
              </label>
              <label>
                <AiTwotonePushpin color="#da3b01" /> อัพเดทยอดขายแบบ Real time
              </label>
              <label>
                <AiTwotonePushpin color="#da3b01" /> ตรวจสอบทีมขายและสินค้าในแต่ละสาขาได้อย่างง่ายดาย
              </label>
              <label>
                <AiTwotonePushpin color="#da3b01" /> คำนวณและตรวจสอบค่า commission
                ได้ทันที
              </label>
              <label style={{ marginBottom: 15 }}>
                <AiTwotonePushpin color="#da3b01" /> ใช้งานง่าย สะดวกทุกที่ แค่มี wifi
              </label>
              <label style={{ marginBottom: 15 }}>
                บอกลาการทำงานแบบเดิมๆ สร้างการเปลี่ยนแปลงให้ #ทีมเซลล์
                ของคุณผ่านเครื่องมือที่ช่วยคุณจัดระเบียบการทำงานเพื่อการมุ่งสู่ยอดขายอย่างรวดเร็วด้วย
                ‘SalesServ’
              </label>
              <label style={{ marginBottom: 15 }}>
                Better Sales, Better Service{" "}
              </label>
              <label>
                ติดต่อสอบถามเพิ่มเติมได้ที่ <FaHandPointDown color="#d1ad8e" />
              </label>
              <label>
                <BsFillBrightnessAltHighFill /> Inbox : m.me/salesserv.co
              </label>
              <label>
                <GiRotaryPhone color="#b50d1b" /> Tel : 064-6359246
              </label>
              <label style={{ marginBottom: 15 }}>
                <BiWorld color="#0fad4c" /> Website:{" "}
                <a href=" https://salesserv.co">https://salesserv.co</a>{" "}
              </label>
              <label>
                #Saleserv #เว็บไซต์ #การจัดการทีมขาย #BetterSalesBetterService
              </label>
            </div>
          </div>
        </Contents>
      </div>
      <Footer />
    </Background>
  );
};

export default SaleServManage;

import styled from "styled-components";

export const ContentsMoblie = styled.div`
  position: fixed;
  height: 100vh;
  width: 0%;
  transition: width 0.2s;
  z-index: 99;
  display: flex;
  overflow: hidden;

  @media only screen and (min-width: 800px) {
    display: none;
  }

  > *:first-child {
    width: 65%;
    background-color: white;

    > *:first-child {
      padding: 4%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .img-logo {
        height: 50px;
        width: 149px;
      }
    }
  }

  > *:last-child {
    background-color: #f15c6a;
    width: 45%;
    opacity: 0.7;
  }
`;

export const NavbarMobile = styled.div`
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  transition: height 0.2s;
  background-color: white;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  z-index: 99;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  @media only screen and (min-width: 800px) {
    display: none;
  }

  > * {
    margin: 2%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const MenuMoblie = styled.div`
  padding: 4%;
  color: #4d4e4e;
  font-weight: 600;
  font-size: 16px;

  > * {
    padding-bottom: 8px;
    padding-top: 8px;
    border-bottom: 1px solid #e5e5e5;
  }
`;

export const ButtomMoblie = styled.div`
  padding: 4%;
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 15px;
    border-radius: 3px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  > *:first-child {
    color: #f15c6a;
    background-color: white;
    border: 2px solid #dc2366;
    font-weight: 700;
  }

  > *:last-child {
    background: linear-gradient(270deg, #f15c6a 0%, rgba(241, 92, 106, 0) 100%),
      #dc2366;
    box-shadow: 0px 0px 40px rgba(255, 143, 155, 0.5);
    font-weight: 400;
    border: 0px;
    color: white;
  }
`;

export const NavbarDesktop = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 99;

  @media only screen and (max-width: 800px) {
    display: none;
  }

  .menu-top {
    width: 100%;
    background: linear-gradient(270deg, #f15c6a 0%, rgba(241, 92, 106, 0) 100%),
      #dc2366;
    display: flex;
    justify-content: space-between;
    padding: 12px 40px 30px 40px;

    > * {
      color: #ffffff;
      font-weight: 700;
      font-size: 14px;

      > * {
        margin-right: 10px;
      }
    }
  }

  .menu-bottom {
    position: absolute;
    top: 40px;
    background-color: white;
    width: 90%;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
    border-radius: 9px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .img-logo {
      width: 160px;

      @media only screen and (max-width: 1000px) {
        width: 120px;
      }
    }

    @media only screen and (max-width: 990px) {
      padding: 10px;
    }
  }
`;

export const MemuTap = styled.div`
  display: flex;
  color: #4d4e4e;
  font-weight: 600;
  width: 100%;
  justify-content: center;

  > * {
    margin-right: 2%;
    margin-left: 2%;
    font-size: 18px;
    cursor: pointer;

    &:hover {
      color: #dc2366;
      text-decoration: underline;
    }

    @media only screen and (max-width: 1000px) {
      font-size: 14px;
    }
  }
`;

export const MemuButtom = styled.div`
  display: flex;

  > * {
    width: 120px;
    height: 45px;

    @media only screen and (max-width: 1000px) {
      font-size: 14px;
      width: 80px;
    }
  }

  > *:first-child {
    margin-right: 15px;
    border-radius: 3px;
    color: #f15c6a;
    background-color: white;
    border: 2px solid #dc2366;
  }

  > *:last-child {
    color: white;
    background: linear-gradient(270deg, #f15c6a 0%, rgba(241, 92, 106, 0) 100%),
      #dc2366;
    border: 0px;
    box-shadow: 0px 0px 40px rgba(255, 143, 155, 0.5);
    border-radius: 3px;
  }
`;

import styled from "styled-components";

export const Background = styled.div`
  background-image: url(${process.env.PUBLIC_URL + "/images/bg-contact.png"});
  background-size: cover;
`;

export const Contenttext = styled.div`
  padding-top: 10%;
  padding-bottom: 2%;
  display: flex;
  flex-direction: column;

  .lable-top {
    color: #dc2366;
    font-weight: 700;
    font-size: 20px;
  }

  .lable-center {
    font-weight: 700;
    font-size: 30px;
    color: #00184b;
  }

  .label-bottom {
    font-weight: 300;
    color: #4d4e4e;
  }
`;

export const ContentsCrad = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 2%;
  padding-bottom: 6%;

  @media only screen and (max-width: 490px) {
    flex-direction: column;
  }

  > * {
    width: 49%;
    @media only screen and (max-width: 490px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`;

export const CardBody = styled.div`
  background: #ffffff;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  padding: 2% 4% 2% 4%;
  display: flex;
  flex-direction: column;

  > *:first-child {
    > * {
      font-weight: 300;
      font-size: 12px;
      margin-bottom: 15px;
    }
    .textarea-cuntom {
      height: 150px;
      resize: none;
    }

    input {
      height: 55px;
    }

    .contact-img {
      object-fit: cover;
      width: 100%;
      height: 431px;
    }
  }

  > *:last-child {
    > * {
      margin-bottom: 15px;
    }

    .icon-img {
      height: 50px;
      width: 50px;
    }

    .contact-text {
      display: flex;
      align-items: center;

      > * {
        margin-right: 15px;
        font-weight: 700;
        font-size: 16px;
        color: #f15c6a;
      }
    }
  }
`;

export const ButtonContact = styled.button`
  padding: 13px 34px 13px 34px;
  background: linear-gradient(270deg, #f15c6a 0%, rgba(241, 92, 106, 0) 100%),
    #dc2366;
  box-shadow: 0px 0px 40px rgba(255, 143, 155, 0.5);
  border-radius: 3px;
  border: 0px;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
`;

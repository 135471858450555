import { Contents, Background } from "../styles/ContentPage";
import Navbar from "../componentes/Navbar";
import Footer from "../componentes/Footer";
import { useNavigate } from "react-router-dom";
import { BsFillBrightnessAltHighFill } from "react-icons/bs";
import { FaHandPointDown } from "react-icons/fa";
import { GiRotaryPhone } from "react-icons/gi";
import { BiWorld } from "react-icons/bi";

const ManageSale = () => {
  const navigate = useNavigate();
  const onChangeHandle = () => {
    navigate("/");
  };
  window.scrollTo(0, 0)
  return (
    <Background>
      <Navbar onChangeHandle={onChangeHandle} />
      <div className="container-sm">
        <Contents>
          <label className="label-header">
            บอกลาวิธีบริหารการขายแบบเดิมๆ กับ Online Sales Management
          </label>
          <div className="content">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/images/Saleserv-June-01-01.jpg"}
                alt={"บอกลาวิธีบริหารการขายแบบเดิมๆ ด้วย Online Sale Management จาก SalesServ"}
              />
            </div>
            <div>
              <label style={{ marginBottom: 15 }}>
                บอกลาวิธีบริหารการขายแบบเดิมๆ ด้วย Online Sales Management
              </label>
              <label style={{ marginBottom: 15 }}>
                ธุรกิจที่อาศัย ‘การขาย’ ไม่ว่าจะขนาดเล็กหรือใหญ่ต้องมีระบบการจัดการงานขายที่ตอบโจทย์ความต้องการอย่างตรงจุด รวดเร็ว และครอบคลุมส่วนต่างๆ อย่าง คลังสินค้า (Warehouse Management) และระบบขายหน้าร้าน POS (พีโอเอส) ผ่านระบบออนไลน์ หรือ Online Sales Management ที่ช่วยลดขั้นตอนการทำงานของทีมขายและเพิ่มความสะดวกในการทำงาน
              </label>
              <label style={{ marginBottom: 15 }}>
                'Online Sales Management' หรือ ‘ระบบจัดการขาย’ สำคัญอย่างไร?
              </label>
              <label style={{ marginBottom: 15 }}>
                SalesServ ระบบบริหารจัดการทีมขายและยอดขายออนไลน์ที่ถูกออกแบบมาให้ใช้งานง่าย ครอบคลุมทุกขั้นตอนการขาย แถมยังมัดรวมระบบ HR เพื่อการพัฒนาองค์กรอย่างมีประสิทธิภาพสูงสุด
              </label>
              <label>
                Better Sales, Better Service
              </label>
              <label style={{ marginBottom: 15 }}>
                เครื่องมือช่วยเพิ่มประสิทธิภาพในการบริหารจัดการทีมขายและยอดขาย
              </label>

              <label>
                ติดต่อสอบถามเพิ่มเติมได้ที่ <FaHandPointDown color="#d1ad8e" />
              </label>
              <label>
                <BsFillBrightnessAltHighFill /> Inbox : m.me/salesserv.co
              </label>
              <label>
                <GiRotaryPhone color="#b50d1b" /> Tel : 064-6359246
              </label>
              <label style={{ marginBottom: 15 }}>
                <BiWorld color="#0fad4c" /> Website:{" "}
                <a href=" https://salesserv.co">https://salesserv.co</a>
              </label>
              <label>
                #SalesServ #เว็บไซต์ #การจัดการทีมขาย #BetterSalesBetterService
              </label>
            </div>
          </div>
        </Contents>
      </div>
      <Footer />
    </Background>
  );
};

export default ManageSale;
